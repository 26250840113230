// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "limits": {
        name: 'Limit |||| Limits',
        fields: {
            ratePerCase: 'Rate per case',
            costApprovalPerCase: 'Cost approval per case',
            currency: 'Currency',
            area: 'Area',
        },
        buttons: {
            add: 'Add limit'
        }
    },
}