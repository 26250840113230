import * as React from "react";
import {Create, NumberInput, ReferenceInput, required, SelectInput, SimpleForm,} from 'react-admin';
import {parse} from 'query-string'

export const LimitCreate = (props) => {

    const {claimsManager: claims_manager_id_string} = parse(props.location.search)

    const claimsManager = claims_manager_id_string
    const redirect = claimsManager ? `/claims-managers/${encodeURIComponent(claimsManager)}/edit` : false

    return (
        <Create {...props} >
            <SimpleForm initialValues={{claimsManager}} redirect={redirect}>
                <ReferenceInput
                    source="claimsManager"
                    reference="claims-managers"
                    allowEmpty
                    validate={required()}
                    filter={{id: claimsManager}}
                >
                    <SelectInput optionText={(record) => `${record.firstName} ${record.lastName}`} disabled/>
                </ReferenceInput>

                <ReferenceInput
                    source="area"
                    reference="areas"
                >
                    <SelectInput optionText="name"/>
                </ReferenceInput>
                <ReferenceInput
                    source="currency"
                    reference="currencies"
                >
                    <SelectInput optionText="name"/>
                </ReferenceInput>

                <NumberInput
                    source="ratePerCase"
                />
                <NumberInput
                    source="costApprovalPerCase"
                />
            </SimpleForm>
        </Create>
    );
}