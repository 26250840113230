export const ROLE_ANONYMOUS = 'ROLE_ANONYMOUS'
export const ROLE_ADMIN = 'ROLE_ADMIN'
export const ROLE_DEALER = 'ROLE_DEALER'
export const ROLE_CLAIMS_MANAGER = 'ROLE_CLAIMS_MANAGER'

export const rolesAvailable = {

  [ROLE_ANONYMOUS]: {
    name: 'roles.ROLE_ANONYMOUS'
  },
  [ROLE_ADMIN]: {
    name: 'roles.ROLE_ADMIN'
  },
  [ROLE_DEALER]: {
    name: 'roles.ROLE_DEALER'
  },
  [ROLE_CLAIMS_MANAGER]: {
    name: 'roles.ROLE_CLAIMS_MANAGER'
  },

}