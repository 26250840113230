import * as React from "react";
import {Edit, NumberInput, ReferenceInput, required, SelectInput, SimpleForm,} from 'react-admin';

export const LimitEdit = (props) => {

    const redirect = (basePath, id, data) => `/claims-managers/${encodeURIComponent(data.claimsManager)}/edit`

    return (
        <Edit {...props} >
            <SimpleForm redirect={redirect}>
                <ReferenceInput
                    source="claimsManager"
                    reference="claims-managers"
                    allowEmpty
                    validate={required()}
                >
                    <SelectInput optionText={(record) => `${record.firstName} ${record.lastName}`} disabled/>
                </ReferenceInput>

                <ReferenceInput
                    source="area"
                    reference="areas"
                >
                    <SelectInput optionText="name"/>
                </ReferenceInput>
                <ReferenceInput
                    source="currency"
                    reference="currencies"
                >
                    <SelectInput optionText="name"/>
                </ReferenceInput>

                <NumberInput
                    source="ratePerCase"
                />
                <NumberInput
                    source="costApprovalPerCase"
                />
            </SimpleForm>
        </Edit>
    );
}