import {deleteRole, deleteToken, getAccessToken, getRole, getTokenClaim, isTokenValid, ROLE_DEALER, setRole, setToken} from './utils/AuthUtils'
import {parse} from 'query-string'
import config from "./config/config";

const authProvider = (history) => ({
    login: (params) => {
        console.log("login", params);
        setToken(params.token, params.refresh_token)
        setRole(params.token)
        return Promise.resolve()
    },
    logout: (params) => {
        console.log("logout");
        deleteToken()
        deleteRole()
        return Promise.resolve()
    },
    checkError: error => {
        console.log("checkError");
        if (401 === error.status || (error.response && 401 === error.response.status)) {
            deleteToken()
            deleteRole()
            return Promise.reject()
        } else {
            return Promise.resolve()
        }
    },
    checkAuth: async () => {
        const {token: urlToken} = parse(history.location.search)
        let token = getAccessToken();
        if (urlToken) {
            const response = await fetch(`${config.login_uuid}`, {
                method: 'POST',
                body: JSON.stringify({
                    token: urlToken,
                    code: '123'
                }),
                headers: {
                    'Content-Type': 'application/json',
                    withCredentials: true
                }
            })
            
            const json = await response.json()
            if (200 !== response.status) {
                throw(new Error(json.error))
            }

            setToken(json.token, json.refreshToken)
            setRole(json.token)
            token = json.token;

        }
        return token && isTokenValid(token) ? Promise.resolve() : Promise.reject()
    },
    getPermissions: () => {
        const {token: urlToken} = parse(history.location.search);
        if (urlToken) {
            return Promise.resolve(ROLE_DEALER);
        }
        const role = getRole()
        return role ? Promise.resolve(role) : Promise.reject()
    },
    getIdentity: () => {
        const token = getAccessToken();
        return Promise.resolve({ fullName: getTokenClaim(token, 'username') });
    }
});

export default authProvider;