import * as React from "react";
import {Edit, ReferenceArrayInput, ReferenceInput, required, SelectArrayInput, SelectInput, SimpleForm} from 'react-admin';

export const SkillEdit = (props) => {

    const redirect = (basePath, id, data) => `/claims-managers/${encodeURIComponent(data.claimsManager)}/edit`

    return (
        <Edit {...props} >
            <SimpleForm redirect={redirect}>
                <ReferenceInput
                    source="claimsManager"
                    reference="claims-managers"
                    allowEmpty
                    validate={required()}
                >
                    <SelectInput optionText={(record) => `${record.firstName} ${record.lastName}`} disabled/>
                </ReferenceInput>

                <ReferenceInput
                    source="area"
                    reference="areas"
                >
                    <SelectInput optionText="name"/>
                </ReferenceInput>
                <ReferenceArrayInput source="carBrands" reference="car-brands" sort={{field: 'name', order: 'ASC'}}>
                    <SelectArrayInput optionText="name"/>
                </ReferenceArrayInput>

            </SimpleForm>
        </Edit>
    );
}