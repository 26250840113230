import React from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import i18n from '../i18n'
import { resolveBrowserLocale, useSetLocale } from 'react-admin'
import { StorageFactory } from '../utils/StorageFactory'

const styles = (theme) => ({
  btn: {
    color: '#fff'
  }
})

const LangsMenu = ({ classes }) => {

  const currentLang = StorageFactory().getItem('APP_LOCALE') || resolveBrowserLocale()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const setLocale = useSetLocale()

  function handleClick (event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose () {
    setAnchorEl(null)
  }

  function setLang (lang) {
    return () => {
      setLocale(lang)
        .then(
          data => {
            StorageFactory().setItem('APP_LOCALE', lang)
            handleClose()
          }
        )
        .catch(error => handleClose())
    }
  }

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className={classes.btn}>
        {currentLang.toUpperCase()}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          Object.keys(i18n).sort().filter(lang => lang !== currentLang).map(lang =>
            <MenuItem
              key={lang}
              selected={currentLang === lang}
              onClick={setLang(lang)}
            >
              {lang.toUpperCase()}
            </MenuItem>
          )
        }
      </Menu>
    </div>
  )
}

export default withStyles(styles)(LangsMenu)