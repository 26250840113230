import React from 'react'
import {useSelector} from 'react-redux'
import {useMediaQuery} from '@material-ui/core'
import compose from 'recompose/compose'
import {withRouter} from 'react-router-dom'
import {MenuItemLink, useTranslate} from 'react-admin'
import {hasRole} from "../utils/AuthUtils";
import {ROLE_ADMIN, ROLE_CLAIMS_MANAGER} from "../config/roles";

const Menu = ({onMenuClick, logout}) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const translate = useTranslate()

    return (
        <div>
            {
                hasRole(ROLE_ADMIN) &&
                <MenuItemLink
                    to={`/policies`}
                    primaryText={translate('menu.policies.name')}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            }
            {
                (hasRole(ROLE_ADMIN) || hasRole(ROLE_CLAIMS_MANAGER)) &&
                <MenuItemLink
                    to={`/damage-reports`}
                    primaryText={translate('menu.damage-reports.name')}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            }
            {
                hasRole(ROLE_ADMIN) &&
                <MenuItemLink
                    to={`/claims-managers`}
                    primaryText={translate('menu.claims-managers.name')}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            }
            {
                hasRole(ROLE_ADMIN) &&
                <MenuItemLink
                    to={`/vat-rates`}
                    primaryText={translate('menu.vat-rates.name')}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            }

            {isXSmall && logout}
        </div>
    )
}

const enhance = compose(
    withRouter,
)

export default enhance(Menu)