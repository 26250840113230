import KJUR from 'jsrsasign'
import _get from 'lodash/get'

export const getTokenExpTimestamp = (token) => {
  return KJUR.jws.JWS.parse(token).payloadObj.exp
}

export const getTokenTTLLeft = (token) => {
  return getTokenExpTimestamp(token) - KJUR.jws.IntDate.get('now')
}

export const getTokenPayload = (token) => {
  return token ? KJUR.jws.JWS.parse(token).payloadObj : {}
}

export const isTokenExpired = (token) => {
  return getTokenTTLLeft(token) < 0
}

export const getTokenData = (token, field) => {
  return _get(getTokenPayload(token), field, null)
}