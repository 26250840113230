import Cookies from 'js-cookie'

export const STORAGE_ADAPTER_COOKIES = 'STORAGE_ADAPTER_COOKIES'
export const STORAGE_ADAPTER_LOCALSTORAGE = 'STORAGE_ADAPTER_LOCALSTORAGE'
export const STORAGE_ADAPTER_LOCALSTORAGE_SYNC = 'STORAGE_ADAPTER_LOCALSTORAGE_SYNC'

class CookiesAdapterSync {
  setItem = (key, val) => Cookies.set(key, val, { expires: 7 })
  getItem = key => Cookies.get(key)
  removeItem = key => Cookies.remove(key)
}

export const StorageFactory = () => {
  return new CookiesAdapterSync()
}