import Area from './Area/en';
import CarBrand from './CarBrand/en';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    resources : {
        ...Area,
        ...CarBrand,
    },
}
