import en from './en';
import de from './de';
import fr from './fr';
import it from './it';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    en,
    fr,
    de,
    it
}