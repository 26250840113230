import React from 'react'
import {Link} from 'react-router-dom'
import AddIcon from '@material-ui/icons/PlaylistAdd'
import {makeStyles} from '@material-ui/core/styles/index'
import {Button, useTranslate} from 'react-admin'

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: '1em'
    }
}));

const AddLimitButton = (props) => {
    const {record} = props
    const translate = useTranslate()
    const classes = useStyles();

    return (
        <Button
            className={classes.button}
            component={Link}
            to={`/limits/create?claimsManager=${record.id}`}
            label={translate('resources.limits.buttons.add')}
            title={translate('resources.limits.buttons.add')}
        >
            <AddIcon/>
        </Button>
    )
}

export default AddLimitButton