import * as React from "react";
import {Edit, TextInput, ReferenceInput, SelectInput, SimpleForm,} from 'react-admin';

export const VATRateEdit = (props) => {
    return (
        <Edit {...props} >
            <SimpleForm>
                <ReferenceInput
                    source="country"
                    reference="countries"
                >
                    <SelectInput optionText="name"/>
                </ReferenceInput>
                <TextInput type="number" source="rate" />
            </SimpleForm>
        </Edit>
    );
}