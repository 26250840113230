import React from 'react'
import { AppBar, UserMenu } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LangsMenu from './LangsMenu'

const useStyles = makeStyles(theme => ({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
}))

const UserMenuWithName = (props) => {
    return (
        <UserMenu {...props} label={'Вася Пупкин'} fullName={'Петя'} />
    )
}

const CustomAppBar = (props) => {

  const classes = useStyles()

  return (
    <AppBar {...props} userMenu={<UserMenuWithName />}>
      <Typography
        variant="h5"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <span className={classes.spacer}/>
      <LangsMenu/>
    </AppBar>
  )

}

export default CustomAppBar