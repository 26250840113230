import {FunctionField, LinearProgress, ReferenceField, useTranslate} from "react-admin";
import * as React from "react";
import {Typography} from "@material-ui/core";
import TransitionButton from "../actions/TransitionButton";
import {useState} from "react";

const Aside = (props) => {
    const {record} = props;
    
    const translate = useTranslate();
    const [showBlock, setShowBlock] = useState(true);

    const buttons = {
        partly_accept: <TransitionButton record={record} showComment={true} onSubmit={() => setShowBlock(false)} transition="partly_accept"/>,
        accept: <TransitionButton record={record} showComment={true} onSubmit={() => setShowBlock(false)} transition="accept"/>,
        refuse: <TransitionButton record={record} showComment={true} onSubmit={() => setShowBlock(false)} transition="refuse"/>,
        reject: <TransitionButton record={record} showComment={true} onSubmit={() => setShowBlock(false)} transition="reject"/>,
        update_claim_data: <TransitionButton record={record} showComment={true} onSubmit={() => setShowBlock(false)} transition="update_claim_data"/>,
        submit_invoice: <TransitionButton record={record} showComment={true} onSubmit={() => setShowBlock(false)} transition="submit_invoice"/>,
        accept_invoice: <TransitionButton record={record} showComment={false} onSubmit={() => setShowBlock(false)} transition="accept_invoice"/>,
        refuse_invoice: <TransitionButton record={record} showComment={true} onSubmit={() => setShowBlock(false)} transition="refuse_invoice"/>,
        payment_done: <TransitionButton record={record} showComment={true} onSubmit={() => setShowBlock(false)} transition="payment_done"/>,
    };

    const buttonsBlock = () => {
        if (record && record.allowedTransitions.length === 0) {
            return (
                <div>
                    <Typography variant="caption">No actions awailable for you at the current stage</Typography>
                </div>
            )
        }
        if (record && record.allowedTransitions.length > 0) {
            return Object.keys(buttons).map(status => {
               if (record.allowedTransitions.includes(status)) {
                    return <div key={status}>
                        {buttons[status]}
                        <Typography variant="caption">&nbsp;</Typography>
                    </div>
                }
                return <span key={status} />
            });
        }

        return <LinearProgress/>
    }

    return (
        <div style={{width: '25%', margin: '1em'}}>
            <Typography variant="h6">Claims manager</Typography>
            <ReferenceField source="claimsManager" reference="claims-managers" record={record} link={false} emptyText={'No claims manager assigned'}>
                <FunctionField render={record => `${record.firstName} ${record.lastName}`}/>
            </ReferenceField>

            <Typography variant="h6">&nbsp;</Typography>
            <Typography variant="h6">Claim state</Typography>
            {
                record ? <Typography variant="body2">{translate(`resources.damage-reports.status.${record.currentWorkflowState}`)}</Typography> : <LinearProgress/>
            }

            <Typography variant="h6">&nbsp;</Typography>
            <Typography variant="h6">Actions</Typography>
            {
                buttonsBlock()
            }
        </div>
    );
}

export default Aside;