// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "skills": {
        name: 'Skill |||| Skills',
        fields: {
            area: 'Area',
            brands: 'Brands'
        },
        helperText : {
            allBrands : 'Leave blank for all brands'
        },
        buttons: {
            add: 'Add skill'
        }
    },
}