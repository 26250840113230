import * as React from "react";
import {Create, TextInput, ReferenceInput, SelectInput, SimpleForm,} from 'react-admin';

export const VATRateCreate = (props) => {

    return (
        <Create {...props} >
            <SimpleForm redirect="list">
                <ReferenceInput
                    source="country"
                    reference="countries"
                >
                    <SelectInput optionText="name"/>
                </ReferenceInput>
                <TextInput type="number" source="rate" />
            </SimpleForm>
        </Create>
    );
}