import {makeStyles} from "@material-ui/core/styles";
import {Toolbar, SaveButton} from "react-admin";
import * as React from "react";

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const EditToolbar = (props) => {
    return (
        <Toolbar {...props} classes={useStyles()}>
            {
                props.canEdit ?
                    <SaveButton redirect="edit" /> :
                    <div/>
            }
        </Toolbar>
    );
}

export default EditToolbar;