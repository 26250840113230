import DamageReport from './DamageReport/fr';
import Policy from './Policy/fr';
import ClaimsManager from './ClaimsManager/fr';
import Settings from './Settings/fr';
import Skill from './Skill/fr';
import Limit from './Limit/fr';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    resources : {
        ...DamageReport,
        ...Policy,
        ...ClaimsManager,
        ...Settings,
        ...Skill,
        ...Limit,
    },
    navigation : {
        first : 'First'
    }
}