import * as React from "react";
import {Datagrid, List, ReferenceField, TextField} from 'react-admin';

export const VATRateList = (props) => {

    return (
        <List
            bulkActionButtons={false}
            sort={{field: 'country.name', order: 'ASC'}}
            exporter={false}
            {...props}
        >
            <Datagrid rowClick="edit">
                <ReferenceField source="country" reference="countries" link={false} sortBy="country.name">
                    <TextField source="name"/>
                </ReferenceField>
                <TextField source="rate"/>
            </Datagrid>
        </List>
    );
}