import React, {useEffect, useState} from 'react';
import Timeline from '@material-ui/lab/Timeline';
import {LoadingIndicator, useDataProvider} from "react-admin";
import LogItem from "./TransitionLog/LogItem";

const TransitionLog = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [logData, setLoadData] = useState([]);
    const dataProvider = useDataProvider();

    useEffect(() => {
        setIsLoading(true);
        dataProvider.getList('damage-report-transition-logs', {
            pagination: {page: 0, perPage: 30},
            sort: {field: "id", order: "DESC"},
            filter: {damageReport: props.record.id}
        })
            .then(({data}) => {
                setLoadData(data);
                setIsLoaded(true);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, [dataProvider, props.record.id]);

    return (
        isLoading ? <LoadingIndicator/> :
            <Timeline align={"alternate"}>
                {
                    isLoaded ? <React.Fragment>

                        {
                            logData.map(log => <LogItem key={`transition-log.${log.id}`} record={log} />)
                        }

                    </React.Fragment> : <React.Fragment/>
                }
            </Timeline>
    );
}

export default TransitionLog;