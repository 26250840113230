// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "claims-managers": {
        name: 'Claims manager |||| Claims managers',
        title: 'Claims managers',
        fields: {

        },
        blocks: {
            password: 'Password',
            contacts: 'Contacts',
            skills: 'Skills'
        }
    }
}