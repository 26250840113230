// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "damage-reports": {
        name: 'application |||| application',
        title: 'Registrare un nuovo danno',
        success: 'Il tuo rapporto sui danni è stato inviato con successo',
        fields: {
            numberId : 'Damage ID',
            warrantyStart: 'Warranty start',
            warrantyEnd: 'Warranty end',
            totalClaims: 'Numbers of claims elapsed',
            mandantCompany: "Mandant",
            currency: 'Valuta',
            createdAt : 'Data di sinistro',
            dealer : 'Società',
            contactFirstName: 'Nome',
            contactLastName: 'Cognome',
            street: 'Strada',
            zip: 'Codice postale',
            city: 'Città',
            country: 'Nazione',
            claimDate : 'Data di sinistro',
            mileage: 'Chilometraggio',
            labourCost: 'Prezzo Lavoro',
            partsCost: 'Prezzo Parti',
            totalCost: 'Prezzo Totale',
            damageDescription: 'Descrizione del sinistro',
            diagnosis: 'Causa / Diagnosi',
            repairDescription: 'Riparazione da eseguire',
            costCalculationFile: 'Carica il calcolo corrispondente',
            vehicleRegistrationFile: 'Carica i documenti di registrazione',
            serviceConfirmationFile: 'Carica il documento di servizio',
            tcsInspectionFile: 'Carica il documento di TCS Rapporto di prova del veicolo',
            status: 'Stato ',
        },
        buttons : {
            submit : 'Inviaree',
        },
        blocks: {
            reporter: 'Contatto del concessionario',
            contact_person: 'Contatta',
            address: 'Indirizzo',
            vehicle: 'Veicolo',
            damage_report: 'Dettagli del danno',
        },
        status: {
            pending: 'In attesa',
            accepted: 'Accettato',
            rejected: 'Declinare',
            refused: 'Rifiutato',
            paid: 'Pagato'
        }
    }
}