import React, {useEffect, useState} from 'react'
import AuthWrapper from './AuthWrapper'
import {MuiThemeProvider} from '@material-ui/core/styles'
import {hideNotification, useLogin, useNotify, useTranslate} from 'react-admin'
import {useDispatch} from 'react-redux'

import {parse} from 'query-string'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import config from '../../config/config'

import AuthLoginPasswordForm from './AuthLoginPasswordForm'
import AuthVinCodeForm from './AuthVinCodeForm'
import AuthLoginLinkForm from './AuthLoginLinkForm'
import {deleteClaimId, getAccessToken, setClaimId} from "../../utils/AuthUtils";
import {getTokenPayload} from "../../utils/JWTUtils";

export const COMPONENT_STATE_DISPLAY = 'COMPONENT_STATE_DISPLAY'
export const COMPONENT_STATE_SUBMITTING = 'COMPONENT_STATE_SUBMITTING'
export const COMPONENT_STATE_SUCCESS = 'COMPONENT_STATE_SUCCESS'
export const COMPONENT_STATE_ERROR = 'COMPONENT_STATE_ERROR'

export const COMPONENT_LOGIN_PASSWORD_FORM = 'COMPONENT_LOGIN_PASSWORD_FORM'
export const COMPONENT_VIN_CODE_FORM = 'COMPONENT_VIN_CODE_FORM'
export const COMPONENT_LOGIN_LINK = 'COMPONENT_LOGIN_LINK'

const AuthRoot = (props) => {

    const urlParams = parse(props.history.location.search);

    const [component, setComponent] = useState(COMPONENT_VIN_CODE_FORM)
    const [componentState, setComponentState] = useState(COMPONENT_STATE_DISPLAY)
    const [credentials, setCredentials] = useState({})
    const [errors, setErrors] = useState({})

    const dispatch = useDispatch()
    const translate = useTranslate()
    const login = useLogin()
    const notify = useNotify();

    const showVinCodeForm = () => {
        setComponent(COMPONENT_VIN_CODE_FORM)
        setComponentState(COMPONENT_STATE_DISPLAY)
        setErrors({})
    }

    const showLoginPasswordForm = () => {
        setComponent(COMPONENT_LOGIN_PASSWORD_FORM)
        setComponentState(COMPONENT_STATE_DISPLAY)
        setErrors({})
    }

    const doLogin = () => {
        const {email, password} = credentials
        const data = {
            username: email,
            password: password,
        }
        setComponentState(COMPONENT_STATE_SUBMITTING)
        fetch(`${config.login}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                withCredentials: true
            }
        })
            .then(async (response) => {
                const json = await response.json()
                if (200 !== response.status) {
                    throw(new Error(json.error))
                }
                dispatch(hideNotification()) // очищаем ошибки авторизации
                await login({token: json.token, refresh_token: json.refreshToken}, '/')
            })
            .catch((e) => {
                handleError('email', 'app.auth.error.bad.credentials')
            })
    }

    const doVinCode = () => {
        const {vin, code} = credentials
        const data = {
            vin: vin,
            code: code,
        }
        setComponentState(COMPONENT_STATE_SUBMITTING)
        fetch(`${config.login_vin_code}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                withCredentials: true
            }
        })
            .then(async (response) => {
                const json = await response.json()
                if (200 !== response.status) {
                    throw(new Error(json.error))
                }
                dispatch(hideNotification()) // очищаем ошибки авторизации
                deleteClaimId();

                const tokenData = getTokenPayload(json.token)
                const {claim = ''} = {...tokenData};

                console.log("claimData", claim);
                if (claim && claim !== '') {
                    setClaimId(claim);
                }

                await login({token: json.token, refresh_token: json.refreshToken}, '/')
            })
            .catch((e) => {
                handleError('vin', 'app.auth.error.policy.not.found')
            })
    }

    const handleSubmit = () => {
        switch (component) {
            case COMPONENT_VIN_CODE_FORM:
                doVinCode()
                break

            default:
                doLogin()
                break
        }
    }

    const handleCredentialsChange = (key, value) => {
        setCredentials(Object.assign({}, credentials, {[key]: value}))
        setErrors({})
    }

    const handleError = (field, error) => {
        setComponentState(COMPONENT_STATE_ERROR)
        setErrors(Object.assign({}, errors, {[field]: error}))
    }

    useEffect(() => {
        if (urlParams.login_link) {
            setComponent(COMPONENT_LOGIN_LINK);
            fetch(urlParams.login_link, {
                method: 'GET'
            })
                .then(async (response) => {
                    const json = await response.json()
                    if (200 !== response.status) {
                        throw(new Error(json.message))
                    }
                    dispatch(hideNotification()) // очищаем ошибки авторизации
                    setClaimId(urlParams.claim_id)
                    await login({token: json.token, refresh_token: json.refreshToken}, '/')
                })
                .catch((e) => {
                    alert(e.message);

                    notify('', {type: "error"});
                })
        }
    }, [])

    let Component = undefined
    let title = ''
    let icon = 'car'

    switch (component) {
        case COMPONENT_VIN_CODE_FORM:
            Component = AuthVinCodeForm
            title = 'app.auth.form.title.vin_code'
            icon = 'icon'
            break

        case COMPONENT_LOGIN_LINK:
            Component = AuthLoginLinkForm
            title = 'app.auth.form.title.login'
            icon = 'signin'
            break

        default:
            Component = AuthLoginPasswordForm
            title = 'app.auth.form.title.login'
            icon = 'signin'
            break
    }

    return (
        <MuiThemeProvider theme={props.theme}>
            <AuthWrapper {...props} title={title} icon={icon}>

                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="stretch"
                >
                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                        style={{marginTop: 12}}
                    >
                        <Component
                            onChange={handleCredentialsChange}
                            componentState={componentState}
                            credentials={credentials}
                            errors={errors}
                            onError={handleError}
                            onSubmit={handleSubmit}
                        />
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                        style={{marginTop: 12}}
                    >
                        {
                            COMPONENT_LOGIN_PASSWORD_FORM === component &&
                            <Grid item xs={12}>
                                <Button
                                    color="secondary"
                                    onClick={() => showVinCodeForm()}
                                >
                                    {translate('app.auth.form.link.vin_code')}
                                </Button>
                            </Grid>
                        }
                        {
                            (COMPONENT_VIN_CODE_FORM === component || COMPONENT_LOGIN_LINK === component) &&
                            <Grid item xs={12}>
                                <Button
                                    color="secondary"
                                    onClick={() => showLoginPasswordForm()}
                                >
                                    {translate('app.auth.form.link.login')}
                                </Button>
                            </Grid>
                        }

                    </Grid>
                </Grid>
            </AuthWrapper>

        </MuiThemeProvider>
    )

}

export default AuthRoot