// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "app.auth.form.title.vin_code": "New damage report",
    "app.auth.form.link.vin_code" : "New damage report",
    "app.auth.form.submit_vin_code" : "Submit",
    "app.auth.form.vin" : "VIN",
    "app.auth.form.code" : "Code on Warranty certificate",
    "app.auth.form.validation.invalid.vin" : "Invalid vin",
    "app.auth.error.policy.not.found" : "Policy not found",

    "app.auth.form.title.login": "Sign in",
    "app.auth.form.link.login": "Password sign in",
    "app.auth.form.link.logout": "Logout",
    "app.auth.form.email": "Email",
    "app.auth.form.firstName": "First name",
    "app.auth.form.lastName": "Last name",
    "app.auth.form.password": "Password",
    "app.auth.form.password.new": "New password",
    "app.auth.form.password.confirm": "Confirm password",
    "app.auth.form.password.changed": "Password habe been changed",
    "app.auth.form.signin": "Sign in",
    "app.auth.form.validation.invalid.email": "Invalid email",
    "app.auth.form.email.not.found": "Email not found",
    "app.auth.form.back": "Back",
    "app.auth.form.register": "Register",
    "app.auth.error.bad.credentials" : "Invalid email or password",
    "app.form.error.passwords.do.not.match": "Passwords doesn't match",
    "app.form.error.passwords.empty": "Password can't be empty",
    "app.form.error.code.empty": "Code can't be empty",
    "app.form.error.code": "Invalid code",
}