import authForm from './authForm/de';
import menu from './menu/de';
import resources from './resoures/de';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "app.loading" : "Beladung",
    "app.welcome" : 'Willkommen',
    "app.form.required" : 'Erforderliches Feld',
    "app.form.submit" : 'Übermitteln',
    "app.form.next" : 'Nächste',
    show : 'Show',
    hide : 'Hide',

    "app.checking" : 'Check läuft',
    "app.gender.male" : 'Männlich',
    "app.gender.female" : 'Weiblich',
    "app.company.customer" : 'Kunde',
    "app.company.provider" : 'Provider',
    "app.company.dealer" : "Firmen",

    roles: {
        ROLE_ANONYMOUS : 'Anonymer Nutzer',
        ROLE_ADMIN : 'Administrator',
        ROLE_EMPLOYEE : 'Mitarbeiter',
        ROLE_COMPANY_ADMIN : 'Firmen Administrator',
        ROLE_COMPANY_MANAGER : 'Firmen Manager',
        ROLE_BRANCH_MANAGER : 'Branch Manager',
        ROLE_DEALER : 'Firmen',
        ROLE_CLAIMS_MANAGER : 'Claims manager',
    },

    dashboard : {
        logged_in_as : 'Angemeldet als',
        role : 'Rolle',
        from_date : 'Datum von',
        to_date : ' Datum bis',
    },

    ...authForm,
    ...menu,
    ...resources

}