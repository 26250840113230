import Area from './Area/de';
import CarBrand from './CarBrand/de';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    resources : {
        ...Area,
        ...CarBrand,
    },
}
