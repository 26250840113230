import authForm from './authForm/en';
import menu from './menu/en';
import resources from './resoures/en';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "app.loading" : "Loading",
    "app.welcome" : 'Welcome',
    "app.form.required" : 'Required field',
    "app.form.submit" : 'Submit',
    "app.form.next" : 'Next',
    show : 'Show',
    hide : 'Hide',

    "app.checking" : 'Check in progress...',
    "app.gender.male" : 'Male',
    "app.gender.female" : 'Female',
    "app.company.customer" : 'Customer',
    "app.company.provider" : 'Provider',
    "app.company.dealer" : "Dealer",

    roles: {
        ROLE_ANONYMOUS : 'Anonymous user',
        ROLE_ADMIN : 'Administrator',
        ROLE_EMPLOYEE : 'Employee',
        ROLE_COMPANY_ADMIN : 'Company administrator',
        ROLE_COMPANY_MANAGER : 'Company manager',
        ROLE_BRANCH_MANAGER : 'Branch manager',
        ROLE_DEALER : 'Dealer',
        ROLE_CLAIMS_MANAGER : 'Claims manager',
    },

    time : {
        days : "%{smart_count} Day |||| %{smart_count} Days",
        hours : "%{smart_count} Hour |||| %{smart_count} Hours",
        minutes : "%{smart_count} Minute |||| %{smart_count} Minutes",
        seconds : "%{smart_count} Second |||| %{smart_count} Seconds",
    },

    dashboard : {
        logged_in_as : 'Logged in as',
        role : 'Role',
        from_date : 'From date',
        to_date : ' To date',
    },


    ...authForm,
    ...menu,
    ...resources

}