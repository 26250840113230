import Area from './Area/fr';
import CarBrand from './CarBrand/fr';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    resources : {
        ...Area,
        ...CarBrand,
    },
}
