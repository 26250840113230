import * as React from "react";
import {Create, email, PasswordInput, ReferenceArrayInput, ReferenceInput, required, SelectArrayInput, SelectInput, SimpleForm, TextInput, useTranslate} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    first: {display: 'inline-block', minWidth: 256},
    second: {display: 'inline-block', marginLeft: 32, minWidth: 256},
    inLine: {display: 'inline-block'},
    double: {minWidth: 544},
    hidden: {display: "none"},
}));

export const ClaimsManagerCreate = (props) => {

    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Create title={translate('resources.claims-managers.title')} {...props} >
            <SimpleForm
                redirect="edit"
            >
                <TextInput source="company" validate={required()}/>
                <TextInput source="firstName" formClassName={classes.first} validate={required()}/>
                <TextInput source="lastName" formClassName={classes.second} validate={required()}/>
                <ReferenceArrayInput source="languages" reference="languages" formClassName={classes.second}>
                    <SelectArrayInput optionText="name"/>
                </ReferenceArrayInput>

                <Typography variant="h6" gutterBottom fullWidth>{translate('resources.claims-managers.blocks.password')}</Typography>
                <PasswordInput source="password"/>

                <Typography variant="h6" gutterBottom fullWidth>{translate('resources.claims-managers.blocks.contacts')}</Typography>

                <TextInput source="street" formClassName={classes.first} validate={required()}/>
                <TextInput source="number" formClassName={classes.second} validate={required()}/>
                <Typography/>
                <TextInput source="zip" formClassName={classes.first} validate={required()}/>
                <TextInput source="city" formClassName={classes.second} validate={required()}/>
                <ReferenceInput source="country" reference="countries" formClassName={classes.second} sort={{field: 'name', order: 'ASC'}} validate={required()}>
                    <SelectInput optionText="name"/>
                </ReferenceInput>
                <Typography/>
                <TextInput source="phone" formClassName={classes.first} validate={required()}/>
                <TextInput source="email" formClassName={classes.second} validate={[required(), email()]}/>

            </SimpleForm>
        </Create>
    );
}