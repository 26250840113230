import {FileField, LoadingIndicator, useDataProvider} from "react-admin";
import {useState} from "react";
import Link from "@material-ui/core/Link";
import config from "../config/config";
import * as React from "react";

const CustomFileField = (props) => {
    const dataProvider = useDataProvider()
    const [started, setStarted] = useState(false)
    const [loading, setLoading] = useState(false)
    const [url, setUrl] = useState(null)

    if (typeof props.record === 'object') {
        return <FileField {...props} />
    } else {
        if (!started) {
            setStarted(true)
            setLoading(true)
            dataProvider
                .getOne('media-objects', {id: props.record})
                .then(({data}) => {
                    setLoading(false)
                    setUrl(data.contentUrl)
                })
        }
        return loading ?
            <LoadingIndicator/> :
            <Link href={`${config.root}${url}`} target="_blank">Download</Link>
    }
}

export default CustomFileField;