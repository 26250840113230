import DamageReport from './DamageReport/en';
import Policy from './Policy/en';
import ClaimsManager from './ClaimsManager/en';
import Settings from './Settings/en';
import Skill from './Skill/en';
import Limit from './Limit/en';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    resources : {
        ...DamageReport,
        ...Policy,
        ...ClaimsManager,
        ...Settings,
        ...Skill,
        ...Limit,
    },
    navigation : {
        first : 'First'
    }
}