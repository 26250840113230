import * as React from "react";
import {BooleanField, ChipField, Datagrid, FunctionField, List, NullableBooleanInput, ReferenceArrayField, ReferenceArrayInput, ReferenceField, ReferenceInput, SelectArrayInput, SelectInput, SingleFieldList, TextField, TextInput} from 'react-admin';
import {getCleanHydraId} from "../../utils/HydraUtils";

const ClaimsManagerFilters = [
    <TextInput source="id"/>,
    <TextInput source="company"/>,
    <TextInput source="secondName" alwaysOn/>,
    <TextInput source="city" alwaysOn/>,
    <ReferenceInput source="country" reference="countries" alwaysOn>
        <SelectInput optionText="name"/>
    </ReferenceInput>,
    <NullableBooleanInput source="isBlocked"/>,
    <ReferenceArrayInput source="languages" reference="languages">
        <SelectArrayInput optionText="name"/>
    </ReferenceArrayInput>
];

const rowStyle = (record, index) => {
    let color = 'white';
    if (record.isBlocked) {
        color = '#EFA774'
    }

    return {
        backgroundColor: color
    }
};

export const ClaimsManagerList = (props) => {

    return (
        <List
            bulkActionButtons={false}
            sort={{field: 'createdAt', order: 'DESC'}}
            filters={ClaimsManagerFilters}
            exporter={false}
            {...props}
        >
            <Datagrid rowClick="edit" rowStyle={rowStyle}>
                <FunctionField source="id" render={record => getCleanHydraId(record.id)}/>
                <TextField source="company"/>
                <TextField source="firstName"/>
                <TextField source="lastName"/>
                <TextField source="city"/>
                <ReferenceField source="country" reference="countries" link={false}>
                    <TextField source="name"/>
                </ReferenceField>
                <ReferenceArrayField source="languages" reference="languages">
                    <SingleFieldList>
                        <ChipField source="code"/>
                    </SingleFieldList>
                </ReferenceArrayField>
                <BooleanField source="isBlocked"/>
            </Datagrid>
        </List>
    );
}