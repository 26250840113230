// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "policies": {
        name: 'application |||| application',
        fields: {
            vin: 'Châssis',
            makeModelText: 'Véhicule',
            licensePlate: 'Plaque d\'immatriculation',
            firstRegistration: 'Première mise en circulation',
            productName: 'Garantie',
            productActivationDate: 'Début de garantie',
            productWarrantyEnd: 'Fin de la garantie',

        },
    }
}