// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "policies": {
        name: 'Damage report |||| Damage reports',
        fields: {
            vin: 'VIN',
            makeModelText: 'Vehicle',
            licensePlate: 'License plate',
            firstRegistration: 'First immatriculation',
            productName: 'Warranty Product',
            productActivationDate: 'Warranty start date',
            productWarrantyEnd: 'Warranty end date',

        },
    }
}