// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "policies": {
        name: 'application |||| application',
        fields: {
            vin: 'Telaio',
            makeModelText: 'Veicolo',
            licensePlate: 'Targa',
            firstRegistration: 'Prima immatricolazione',
            productName: 'Garanzia',
            productActivationDate: 'Inizio della garanzia',
            productWarrantyEnd: 'Fine della garanzia',

        },
    }
}