import React from 'react'
import { Resource } from 'react-admin'
import {PoliciesList} from "./List";


// eslint-disable-next-line import/no-anonymous-default-export
export default permissions => [
  <Resource
    name="policies"
    list={PoliciesList}
  />
]