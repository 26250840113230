import DamageReport from './DamageReport/it';
import Policy from './Policy/it';
import ClaimsManager from './ClaimsManager/it';
import Settings from './Settings/it';
import Skill from './Skill/it';
import Limit from './Limit/it';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    resources : {
        ...DamageReport,
        ...Policy,
        ...ClaimsManager,
        ...Settings,
        ...Skill,
        ...Limit,
    },
    navigation : {
        first : 'First'
    }
}