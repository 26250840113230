// eslint-disable-next-line import/no-anonymous-default-export
export default {
    menu: {
        dashboard: {
            name: 'Tableau de bord'
        },
        policies : {
            name: 'Polizze'
        },
        'damage-reports' : {
            name : 'Danni'
        },
        'claims-managers' : {
            name : 'Claims managers'
        },
        'vat-rates': {
            name: 'VAT Rates'
        }
    }
}