import config from '../../config/config'
import { getAccessToken } from '../../utils/AuthUtils'

const uploadFile = async (fileObj) => {
  const uploadRequest = new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('file', fileObj.rawFile)

    const request = new XMLHttpRequest()
    request.onload = function (ev) {
      if (request.status < 200 || request.status > 300) {
        reject()
      } else {
        const response = JSON.parse(request.response)
        resolve(response['@id'])
      }
    }
    request.open('POST', new URL('/api/media-objects', config.api))
    request.setRequestHeader('Authorization', 'Bearer ' + getAccessToken())
    request.setRequestHeader('Accept', 'application/ld+json')
    request.send(formData)
  })

  return await uploadRequest
}

const addUploadFeature = dataProvider => ({
  ...dataProvider,
  create: async (resource, params) => {
    if (resource === 'damage-reports' && params.data.costCalculationFile && typeof params.data.costCalculationFile === 'object') {
      const costCalculationUploadResult = uploadFile(params.data.costCalculationFile);
      params.data.costCalculationFile = await costCalculationUploadResult;
    }
    if (resource === 'damage-reports' && params.data.vehicleRegistrationFile && typeof params.data.vehicleRegistrationFile === 'object') {
      const vehicleRegistrationUploadResult = uploadFile(params.data.vehicleRegistrationFile);
      params.data.vehicleRegistrationFile = await vehicleRegistrationUploadResult;
    }
    if (resource === 'damage-reports' && params.data.serviceConfirmationFile && typeof params.data.serviceConfirmationFile === 'object') {
      const serviceConfirmationUploadResult = uploadFile(params.data.serviceConfirmationFile);
      params.data.serviceConfirmationFile = await serviceConfirmationUploadResult;
    }
    if (resource === 'damage-reports' && params.data.tcsInspectionFile && typeof params.data.tcsInspectionFile === 'object') {
      const tcsInspectionUploadResult = uploadFile(params.data.tcsInspectionFile);
      params.data.tcsInspectionFile = await tcsInspectionUploadResult;
    }

    return dataProvider.create(resource, params)
  },
  update: async (resource, params) => {
    if (resource === 'damage-reports' && params.data.costCalculationFile && typeof params.data.costCalculationFile === 'object') {
      const costCalculationUploadResult = uploadFile(params.data.costCalculationFile);
      params.data.costCalculationFile = await costCalculationUploadResult;
    }
    if (resource === 'damage-reports' && params.data.vehicleRegistrationFile && typeof params.data.vehicleRegistrationFile === 'object') {
      const vehicleRegistrationUploadResult = uploadFile(params.data.vehicleRegistrationFile);
      params.data.vehicleRegistrationFile = await vehicleRegistrationUploadResult;
    }
    if (resource === 'damage-reports' && params.data.serviceConfirmationFile && typeof params.data.serviceConfirmationFile === 'object') {
      const serviceConfirmationUploadResult = uploadFile(params.data.serviceConfirmationFile);
      params.data.serviceConfirmationFile = await serviceConfirmationUploadResult;
    }
    if (resource === 'damage-reports' && params.data.tcsInspectionFile && typeof params.data.tcsInspectionFile === 'object') {
      const tcsInspectionUploadResult = uploadFile(params.data.tcsInspectionFile);
      params.data.tcsInspectionFile = await tcsInspectionUploadResult;
    }
    
    return dataProvider.update(resource, params)
  }
})

const decorators = [
  addUploadFeature
]

export default decorators;