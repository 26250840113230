import React from 'react'
import {Resource} from 'react-admin'
import {DamageReportCreate} from "./Create";
import {DamageReportList} from "./List";
import {DamageReportEdit} from "./Edit";


// eslint-disable-next-line import/no-anonymous-default-export
export default permissions => [
    <Resource
        name="damage-reports"
        create={DamageReportCreate}
        edit={DamageReportEdit}
        list={DamageReportList}
    />,
    <Resource
        name="currencies"
    />,
    <Resource
        name="countries"
    />,
    <Resource
        name="damage-reports/transition"
    />,
    <Resource
        name="damage-report-transition-logs"
    />
]