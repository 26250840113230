import Area from './Area/it';
import CarBrand from './CarBrand/it';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    resources : {
        ...Area,
        ...CarBrand,
    },
}
