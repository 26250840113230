import React from 'react'
import { Resource } from 'react-admin'
import {SkillEdit} from "./Edit"
import {SkillCreate} from "./Create"

// eslint-disable-next-line import/no-anonymous-default-export
export default permissions => [
  <Resource
      name="skills"
      create={SkillCreate}
      edit={SkillEdit}
  />,

  <Resource
      name="skill-brands"
  />,
  <Resource
      name="car-brands"
  />,
]