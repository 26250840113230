import DamageReport from './DamageReport/de';
import Policy from './Policy/de';
import ClaimsManager from './ClaimsManager/de';
import Settings from './Settings/de';
import Skill from './Skill/de';
import Limit from './Limit/de';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    resources : {
        ...DamageReport,
        ...Policy,
        ...ClaimsManager,
        ...Settings,
        ...Skill,
        ...Limit,
    },
    navigation : {
        first : 'First'
    }
}
