import * as React from "react";
import {Datagrid, List, TextField} from 'react-admin';

export const PoliciesList = (props) => (
    <List bulkActionButtons={false} {...props}>
        <Datagrid>
            <TextField source="brand"/>
            <TextField source="model"/>
            <TextField source="vin"/>
        </Datagrid>
    </List>
);