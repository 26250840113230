import React from 'react';
import {fetchHydra as baseFetchHydra, HydraAdmin, hydraDataProvider as baseHydraDataProvider, useIntrospection} from '@api-platform/admin';
import {Helmet} from 'react-helmet'
import {resolveBrowserLocale} from 'react-admin'
import {JssProvider} from 'react-jss'
import {createTheme} from '@material-ui/core/styles'
import CustomLayout from './Layout/Layout'
import Resources from './components/resources'
import AuthRoot from './components/Auth/AuthRoot'
import authProvider from './authProvider'
import {createHashHistory} from 'history'
import config from './config/config'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import frenchCommonMessages from 'ra-language-french'
import englishCommonMessages from 'ra-language-english'
import germanCommonMessages from 'ra-language-german'
import russianCommonMessages from 'ra-language-russian'
import italianCommonMessages from 'ra-language-italian'
import * as domainMessages from './i18n'
import {StorageFactory} from './utils/StorageFactory'
import { parseHydraDocumentation } from "@api-platform/api-doc-parser";
import {Redirect, Route} from "react-router-dom";
import {deleteToken, getAccessToken} from "./utils/AuthUtils";
import dataProviderDecorators, {decorateDataProvider} from './components/dataProviderDecorators'
import {parse} from 'query-string'

const history = createHashHistory()

const theme = createTheme({
    palette: {
        primary: {
            main: config.themeConfig.primary
        },
        secondary: {
            main: config.themeConfig.secondary
        }
    },
})

const messages = {
    fr: {...frenchCommonMessages, ...domainMessages.default.fr},
    en: {...englishCommonMessages, ...domainMessages.default.en},
    de: {...germanCommonMessages, ...domainMessages.default.de},
    ru: {...russianCommonMessages, ...domainMessages.default.ru},
    it: {...italianCommonMessages, ...domainMessages.default.it}
}

const initLocale = StorageFactory().getItem('APP_LOCALE') || resolveBrowserLocale()
const i18nProvider = polyglotI18nProvider(
    locale => messages[locale],
    initLocale,
    {allowMissing: true}
)

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

const getHeaders = () => getAccessToken() ? {
    Authorization: `Bearer ${getAccessToken()}`,
} : {};

const fetchHydra = (url, options = {}) =>
    baseFetchHydra(url, {
        ...options,
        headers: getHeaders,
    });

const RedirectToLogin = () => {
    const introspect = useIntrospection();
    const {token: urlToken} = parse(history.location.search);

    if (getAccessToken() || urlToken) {
        introspect();
        return <></>;
    }

    return <Redirect to="/login"/>;
};

const apiDocumentationParser = async (entrypoint) => {
    try {
        const {api} = await parseHydraDocumentation(entrypoint, {headers: getHeaders});
        return {api};
    } catch (result) {
        if (result.status === 401) {
            // Prevent infinite loop if the token is expired
            deleteToken()

            return {
                api: result.api,
                customRoutes: [
                    <Route path="/" component={RedirectToLogin}/>
                ],
            };
        }

        throw result;
    }
};
const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser);

const App = () => {

    return (
        <JssProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{config.themeConfig.defaultTitle}</title>
                <link rel="shortcut icon" href={config.themeConfig.favicon.default}/>
            </Helmet>

            <HydraAdmin
                entrypoint={process.env.REACT_APP_API_ENTRYPOINT}
                dataProvider={decorateDataProvider(dataProviderDecorators)(dataProvider)}
                theme={theme}
                layout={CustomLayout}
                i18nProvider={i18nProvider}
                loginPage={AuthRoot}
                authProvider={authProvider(history)}
                disableTelemetry
            >
                {
                    permissions => Resources(permissions)
                }
            </HydraAdmin>

        </JssProvider>
    );
}

export default App;