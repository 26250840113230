import DamageReport from './DamageReport/resources'
import Policy from './Policy/resources';
import ClaimsManager from './ClaimsManager/resources';
import Limit from './Limit/resources';
import Skill from './Skill/resources';
import VATRate from './VATRate/resources';

// eslint-disable-next-line import/no-anonymous-default-export
export default permissions => [
    ...DamageReport(permissions),
    ...ClaimsManager(permissions),
    ...Policy(permissions),
    ...Limit(permissions),
    ...Skill(permissions),
    ...VATRate(permissions),
]