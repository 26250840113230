import React from 'react'
import { Resource } from 'react-admin'
import {LimitEdit} from "./Edit"
import {LimitCreate} from "./Create"

// eslint-disable-next-line import/no-anonymous-default-export
export default permissions => [
  <Resource
      name="limits"
      create={LimitCreate}
      edit={LimitEdit}
  />
]