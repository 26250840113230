// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "policies": {
        name: 'Anwendung |||| Anwendungen',
        fields: {
            vin: 'VIN Nr.',
            makeModelText: 'Fahrzeug',
            licensePlate: 'Kennzeichen',
            firstRegistration: 'Erstzulassung',
            productName: 'Garantie Produkt',
            productActivationDate: 'Garantie Startdatum',
            productWarrantyEnd: 'Garantie Enddatum',

        },
    },
}