import * as React from "react";
import {
    BooleanInput,
    ChipField,
    Datagrid,
    DeleteButton,
    Edit,
    EditButton,
    email,
    PasswordInput,
    ReferenceArrayField,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    ReferenceManyField,
    required,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    SingleFieldList,
    TextField,
    TextInput,
    useTranslate
} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import AddLimitButton from "../Limit/AddLimitButton";
import AddSkillButton from "../Skill/AddSkillButton";

const useStyles = makeStyles(theme => ({
    first: {display: 'inline-block', minWidth: 256},
    second: {display: 'inline-block', marginLeft: 32, minWidth: 256},
    inLine: {display: 'inline-block'},
    double: {minWidth: 544},
    hidden: {display: "none"},
}));

export const ClaimsManagerEdit = (props) => {

    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Edit title={translate('resources.claims-managers.title')} {...props} >
            <SimpleForm
                redirect="edit"
            >
                <TextInput source="company" validate={required()}/>
                <TextInput source="firstName" formClassName={classes.first} validate={required()}/>
                <TextInput source="lastName" formClassName={classes.second} validate={required()}/>

                <ReferenceArrayInput source="languages" reference="languages" formClassName={classes.second}>
                    <SelectArrayInput optionText="name"/>
                </ReferenceArrayInput>

                <Typography variant="h6" gutterBottom fullWidth>{translate('resources.claims-managers.blocks.password')}</Typography>
                <PasswordInput source="password"/>

                <Typography variant="h6" gutterBottom fullWidth>{translate('resources.claims-managers.blocks.contacts')}</Typography>

                <TextInput source="street" formClassName={classes.first} validate={required()}/>
                <TextInput source="number" formClassName={classes.second} validate={required()}/>
                <Typography/>
                <TextInput source="zip" formClassName={classes.first} validate={required()}/>
                <TextInput source="city" formClassName={classes.second} validate={required()}/>
                <ReferenceInput source="country" reference="countries" formClassName={classes.second} sort={{field: 'name', order: 'ASC'}} validate={required()}>
                    <SelectInput optionText="name"/>
                </ReferenceInput>

                <Typography/>
                <TextInput source="phone" formClassName={classes.first} validate={required()}/>
                <TextInput source="email" formClassName={classes.second} validate={[required(), email()]}/>
                <Typography/>
                <BooleanInput source="isBlocked"/>

                <Typography variant="h5">{translate('resources.limits.name', {smart_count: 2})}</Typography>
                <ReferenceManyField reference="limits" target="claimsManager" addLabel={false}>
                    <Datagrid>
                        <ReferenceField source="area" reference="areas" link={false}>
                            <TextField source="name"/>
                        </ReferenceField>
                        <ReferenceField source="currency" reference="currencies" link={false}>
                            <TextField source="name"/>
                        </ReferenceField>
                        <TextField source="ratePerCase"/>
                        <TextField source="costApprovalPerCase"/>
                        <EditButton label=""/>
                        <DeleteButton label="" redirect={false}/>
                    </Datagrid>
                </ReferenceManyField>
                <AddLimitButton/>

                <Typography variant="h5">&nbsp;</Typography>
                <Typography variant="h5">{translate('resources.skills.name', {smart_count: 2})}</Typography>
                <ReferenceManyField reference="skills" target="claimsManager" addLabel={false}>
                    <Datagrid>
                        <ReferenceField source="area" reference="areas" link={false}>
                            <TextField source="name"/>
                        </ReferenceField>
                        <ReferenceArrayField source="carBrands" reference="car-brands">
                            <SingleFieldList>
                                <ChipField source="name"/>
                            </SingleFieldList>
                        </ReferenceArrayField>
                        <EditButton label=""/>
                        <DeleteButton label="" redirect={false}/>
                    </Datagrid>
                </ReferenceManyField>
                <AddSkillButton/>
            </SimpleForm>
        </Edit>
    );
}