import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {LoadingIndicator, LinearProgress} from 'react-admin'

const styles = theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    cell: {
        padding: 0,
        textAlign: 'center'
    },
    textField: {
        width: '100%'
    },
    submit: {
        marginTop: theme.spacing(3),
    },
    link: {
        textDecoration: 'none',
        padding: '10px'
    }
})

const AuthLoginLinkForm = (props) => {

    const {
        classes
    } = props

    return (
        <Grid
            container
            spacing={0}
            style={{width: '100%'}}
        >
            <Grid item xs={12} className={classes.cell}>
                <LinearProgress/>
            </Grid>

        </Grid>
    )

}

AuthLoginLinkForm.propTypes = {
    classes: PropTypes.object.isRequired,
    errors: PropTypes.object,
    credentials: PropTypes.object,
    componentState: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onError: PropTypes.func
}

export default withStyles(styles)(AuthLoginLinkForm)