import * as React from "react";
import {Edit, useTranslate} from 'react-admin';
import EditAside from "./components/EditAside";
import {EditForm} from "./components/EditForm";


const ClaimTitle = (props) => {
    const translate = useTranslate();
    return <span>{translate('resources.damage-reports.name',{smart_count: 1})}</span>;
};

export const DamageReportEdit = (props) => {

    const {permissions} = props;

    return (
        <Edit aside={<EditAside/>} title={<ClaimTitle />} {...props} >
            <EditForm permissions={permissions} />
        </Edit>
    );
}