// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "damage-reports": {
        name: 'Anwendung |||| Anwendungen',
        title: 'Neuen Schaden registrieren',
        success: 'Ihre Schadensmeldung wurde erfolgreich gesendet',
        fields: {
            numberId : 'Damage ID',
            warrantyStart: 'Warranty start',
            warrantyEnd: 'Warranty end',
            totalClaims: 'Numbers of claims elapsed',
            mandantCompany: "Mandant",
            currency: 'Währung',
            createdAt : 'Schadendatum',
            dealer : 'Firma',
            contactFirstName: 'Vorname',
            contactLastName: 'Nachname',
            street: 'Strasse',
            zip: 'Postleitzahl',
            city: 'Ort',
            country: 'Land',
            claimDate : 'Schadendatum',
            mileage: 'Kilometerstand',
            labourCost: 'Kosten Arbeit',
            partsCost: 'Kosten Teile',
            totalCost: 'Kosten Total',
            damageDescription: 'Schadenbeschreibung',
            diagnosis: 'Ursache / Diagnose',
            repairDescription: 'Reparaturbeschrieb',
            costCalculationFile: 'Bitte laden Sie die entsprechende Berechnung hoch',
            vehicleRegistrationFile: 'Bitte laden Sie die Registrierungspapiere hoch',
            serviceConfirmationFile: 'Bitte laden Sie die Servicebestätigungen hoch',
            tcsInspectionFile: 'Bitte laden Sie den TCS Prüfbericht hoch',
            status: 'Status',
        },
        buttons : {
            submit : 'Senden',
        },
        blocks: {
            reporter: 'Händler Kontakt',
            contact_person: 'Kontaktperson',
            address: 'Adresse',
            vehicle: 'Fahrzeug',
            damage_report: 'Schadendetails',
        },
        status: {
            pending: 'Pendent',
            accepted: 'Akzeptiert',
            rejected: 'Zurückgewiesen',
            refused: 'Abgelehnt',
            paid: 'Bezahlt'
        }
    },
}