import React from 'react'
import { Layout } from 'react-admin'
import AppBar from './AppBar'
import AppMenu from './Menu'

const CustomLayout = (props) => <Layout
  {...props}
  appBar={AppBar}
  menu={AppMenu}

/>

export default CustomLayout