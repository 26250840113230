import React from 'react'

import Avatar from '@material-ui/core/Avatar'
import {withStyles} from '@material-ui/core/styles'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import {useTranslate} from 'react-admin'

const styles = theme => {
    return {
        root: {},
        logo: {
            maxWidth: 80,
            margin: 20
        },
        main: {
            width: 'auto',
            display: 'block', // Fix IE 11 issue.
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            [theme.breakpoints.up(480 + theme.spacing(3 * 2))]: {
                width: 480,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
        paper: {
            marginTop: theme.spacing(8),
            marginBottom: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.primary.main,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            marginTop: theme.spacing(3),
        },
    }
}

const AuthWrapper = (props) => {

    const translate = useTranslate()
    const {classes, children, title, icon} = props

    return (
        <div className={classes.root}>
            <img src='/400dpiLogoCropped.png' className={classes.logo}  alt='' />
            <main className={classes.main}>
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        {
                            icon === 'signin' ? <LockOutlinedIcon/> : <DirectionsCarIcon/>
                        }
                    </Avatar>
                    <Typography variant="h5" gutterBottom>{translate(title)}</Typography>

                    {children}

                </Paper>
            </main>
        </div>
    )

}

export default withStyles(styles)(AuthWrapper)