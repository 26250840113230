import React from 'react'
import { Resource } from 'react-admin'
import {VATRateCreate} from "./Create";
import {VATRateList} from "./List";
import {VATRateEdit} from "./Edit";


// eslint-disable-next-line import/no-anonymous-default-export
export default permissions => [
  <Resource
    name="vat-rates"
    create={VATRateCreate}
    edit={VATRateEdit}
    list={VATRateList}
  />,
]