import * as React from "react";
import {FunctionField, Datagrid, DateField, List, NumberInput, ReferenceField, SelectInput, TextField, TopToolbar, usePermissions, useRedirect, useTranslate} from 'react-admin';
import {makeStyles} from '@material-ui/core';
import {getClaimId, hasRole} from "../../utils/AuthUtils";
import {ROLE_ADMIN, ROLE_CLAIMS_MANAGER, ROLE_DEALER} from "../../config/roles";

const useStyles = makeStyles({
    smallFont: {
        fontSize: "70%",
    }
});


const rowStyle = (record, index) => {
    let color;
    switch (record.currentWorkflowState) {
        case 'new':
            color = '#B1E5F6'
            break

        case 'paid':
            color = '#efe'
            break

        case 'rejected':
            color = '#EFA774'
            break

        default:
            color = 'white'
    }

    return {
        backgroundColor: color
    }
};

const statuses = [
    {id: 'new', name: 'resources.damage-reports.status.new'},
    {id: 'rejected', name: 'resources.damage-reports.status.rejected'},
    {id: 'awaiting_second_approval', name: 'resources.damage-reports.status.awaiting_second_approval'},
    {id: 'awaiting_dealer_rectification', name: 'resources.damage-reports.status.awaiting_dealer_rectification'},
    {id: 'accepted', name: 'resources.damage-reports.status.accepted'},
    {id: 'awaiting_invoice_approval', name: 'resources.damage-reports.status.awaiting_invoice_approval'},
    {id: 'invoice_accepted', name: 'resources.damage-reports.status.invoice_accepted'},
    {id: 'invoice_refused', name: 'resources.damage-reports.status.invoice_refused'},
    {id: 'paid', name: 'resources.damage-reports.status.paid'}
]


const DamageReportFilters = [
    <NumberInput source="numberId" alwaysOn/>,
    <SelectInput source="currentWorkflowState" choices={statuses} alwaysOn/>
];

const ListActions = (props) => {
    return (
        <TopToolbar>
            {
                //cloneElement(props.filters, { context: 'button' })}
            }
        </TopToolbar>
    );
}

export const DamageReportList = (props) => {
    const classes = useStyles();
    const {permissions} = usePermissions();
    const redirect = useRedirect();
    const translate = useTranslate();

    if (hasRole(ROLE_DEALER, permissions)) {
        const claim = getClaimId();
        if (claim && claim.length) {
            redirect('edit', '/damage-reports', getClaimId())
        }
        else {
            console.log('redirect to create')
            redirect('create', '/damage-reports')
        }
    }

    return (hasRole(ROLE_ADMIN, permissions) || hasRole(ROLE_CLAIMS_MANAGER, permissions)) && (
        <List
            bulkActionButtons={false}
            sort={{field: 'createdAt', order: 'DESC'}}
            filters={DamageReportFilters}
            exporter={false}
            actions={<ListActions/>}
            {...props}
        >
            <Datagrid rowClick="edit" rowStyle={rowStyle} classes={{headerCell: classes.smallFont, rowCell: classes.smallFont}}>
                <TextField source="numberId"/>
                <DateField source="claimDate"/>
                <ReferenceField label="resources.damage-reports.fields.mandantCompany" source="policy" reference="policies" link={false}>
                    <TextField source="mandantCompany"/>
                </ReferenceField>
                <ReferenceField label="resources.policies.fields.productName" source="policy" reference="policies" link={false}>
                    <TextField source="productShortName"/>
                </ReferenceField>
                <ReferenceField label="resources.policies.fields.makeModelText" source="policy" reference="policies" link={false}>
                    <TextField source="makeModelText"/>
                </ReferenceField>
                <ReferenceField label="resources.policies.fields.vin" source="policy" reference="policies" link={false}>
                    <TextField source="vin"/>
                </ReferenceField>
                <ReferenceField label="resources.damage-reports.fields.currency" source="currency" reference="currencies" link={false}>
                    <TextField source="name"/>
                </ReferenceField>
                <TextField source="totalCost"/>
                <ReferenceField label="resources.policies.fields.productActivationDate" source="policy" reference="policies" link={false}>
                    <DateField source="productActivationDate"/>
                </ReferenceField>
                <ReferenceField label="resources.policies.fields.productWarrantyEnd" source="policy" reference="policies" link={false}>
                    <DateField source="productWarrantyEnd"/>
                </ReferenceField>
                <FunctionField source="status"  render={record => translate(`resources.damage-reports.status.${record.currentWorkflowState}`)} />
                <TextField source="totalClaims"/>
            </Datagrid>
        </List>
    );
}