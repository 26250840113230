import React from 'react'
import {Resource} from 'react-admin'
import {ClaimsManagerCreate} from "./Create";
import {ClaimsManagerList} from "./List";
import {ClaimsManagerEdit} from "./Edit";


const resources = permissions => [
    <Resource
        name="claims-managers"
        create={ClaimsManagerCreate}
        edit={ClaimsManagerEdit}
        list={ClaimsManagerList}
    />,
    <Resource
        name="languages"
    />,
    <Resource
        name="areas"
    />,
    <Resource
        name="car-brands"
    />,
    <Resource
        name="suggest-claims-managers"
    />,
]

export default resources;